import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '@sc-ui';
import { global } from '../../global';
import { TracingService } from 'src/services/tracing.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  contact = global.contact;

  constructor(private ga : GoogleAnalyticsService, public tracingService : TracingService) { }

  ngOnInit() {
  }

  toPhoneHref(href : string)
  {
    if (!href) return "";
    return "tel:" + href.replace(/:/g, "").replace(/ /g, "",).replace(/-/g, "");
  }

  goToBooking()
  {    
    this.ga.track('bookingclick');
    window.open(this.tracingService.getDemoLink(), '_blank').focus();
  }

}
