import { Component } from '@angular/core';
import { GoogleAnalyticsService } from '@sc-ui';
import { TracingService } from 'src/services/tracing.service';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss']
})
export class WebinarComponent {

  author = {
    "Firstname": "Lars",
    "Lastname": "Branscheid",
    "Image": "https://screcruiting.blob.core.windows.net/publicassets/lars-avatar.jpg"
  }

  constructor(public tracingService : TracingService) { }

  goToBooking()
  {    
    window.open(this.tracingService.appendUtm('https://calendly.com/skillconomy-jk/webinar-ki-im-recruiting?month=2024-11'), '_blank').focus();
  }

}
