import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TracingService implements OnDestroy {

  private sessionEnded$ = new Subject<void>();

  private isTrackingSuspended = true; 

  public tracingInfo = {
    utmSource: '',
    utmCampaign: '',    
    referrer: '',
    firstUtmSource: '',
    firstReferrer: '',
    firstUtmCampaign: '',
    deviceId: '',
    userAgent: '',
    id: ''
  }

  private eventBuffer : any = [];

  constructor(private route: ActivatedRoute, private router: Router, private api: ScApiService) {

    timer(5000).pipe(takeUntil(this.sessionEnded$)).subscribe(() => {
      this.isTrackingSuspended = false;
      this.activate();
    });
    
  }

  activate()
  {
    this.inferUtmSource();

    if (!this.tracingInfo.deviceId) {
      this.initTracingInfo();
      this.traceFirstVisit();
    }

    this.saveTracingInfo();

    this.isTrackingSuspended = false;

    this.eventBuffer.forEach(t => {
      this.trace(t.TraceId, t.Payload);
    });
  }

  public init() {

    this.loadTracingInfo();

    this.tracingInfo.referrer = document.referrer;
    this.tracingInfo.userAgent = navigator.userAgent;
    this.tracingInfo.utmSource = '';
    this.tracingInfo.utmCampaign = '';

    this.route.queryParams.subscribe((params: any) => {

      if (params.id || params.csc || params.utm_source) {
        
        this.tracingInfo.utmSource = params.utm_source;
        this.tracingInfo.utmCampaign = params.utm_campaign;     
        this.tracingInfo.id = params.id; 

        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { csc: null, id: null, utm_source: null, utm_campaign: null },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
      }
    });

    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        this.tracePageView();
      }
    });
  }

  private trace(traceId, payload) {

    var trace = {
      TraceId: traceId,
      UtmSource: this.tracingInfo.utmSource,
      UtmCampaign: this.tracingInfo.utmCampaign,
      ClientId: this.tracingInfo.deviceId,
      CandidateId: this.tracingInfo.id,
      Payload: JSON.stringify(payload)
    }

    if (this.isTrackingSuspended)
    {
      this.eventBuffer.push(trace);
      return;
    }

    this.api.post('trace', trace).subscribe();
  }

  tracePageView() {

    var currentUrl = this.router.url;

    var payload = {
      Url: currentUrl,
      TracingInfo: this.tracingInfo
    };

    this.trace('page_view', payload);
  }

  traceFirstVisit() {

    var currentUrl = encodeURIComponent(this.router.url);

    var payload = {
      Url: currentUrl,
      TracingInfo: this.tracingInfo
    };

    this.trace('first_visit', payload);

  }

  getDemoLink()
  {
    var link = 'https://calendly.com/skillconomy-kennenlernen/buchen';
    
    link += '?utm_source=' + this.tracingInfo.utmSource; 
    link += '&utm_campaign=' + this.tracingInfo.utmCampaign; 
    if (this.tracingInfo.id)
      link += '&salesforce_uuid=' + this.tracingInfo.id; 
    
    return link;
  }

  appendUtm(link)
  {
    var appendChar = '?';

    if (link.indexOf('?') > 0) appendChar = '&';

    link += appendChar + 'utm_source=' + this.tracingInfo.utmSource; 
    link += '&utm_campaign=' + this.tracingInfo.utmCampaign; 
    if (this.tracingInfo.id)
      link += '&salesforce_uuid=' + this.tracingInfo.id;     
    return link;
  }

  saveTracingInfo() {
    var json = JSON.stringify(this.tracingInfo);
    localStorage.setItem('tracing', json);
  }

  loadTracingInfo() {

    var json = localStorage.getItem('tracing');

    if (json) {
      var tracingInfo = JSON.parse(json);
      Object.assign(this.tracingInfo, tracingInfo);
    } else {
      console.log("No tracing info present...");
    }
  }

  initTracingInfo() {
    var deviceId = this.generateRandomKey(128);
    this.tracingInfo.deviceId = deviceId;
    this.tracingInfo.firstUtmSource = this.tracingInfo.utmSource;
    this.tracingInfo.firstUtmCampaign = this.tracingInfo.utmCampaign;
    this.tracingInfo.firstReferrer = this.tracingInfo.referrer;
    this.saveTracingInfo();
  }

  generateRandomKey(length) {
    const array = new Uint8Array(length / 8); // length in bytes
    crypto.getRandomValues(array);
    return Array.from(array, byte => ('00' + byte.toString(16)).slice(-2)).join('');
  }

  inferUtmSource() {
    if (this.tracingInfo.utmSource == 'cpc' && this.tracingInfo.referrer.indexOf('google.') >= 0) {
      this.tracingInfo.utmSource = 'google_ads';
    }

    if (this.tracingInfo.utmSource == '' && this.tracingInfo.referrer.indexOf('google.') >= 0) {
      this.tracingInfo.utmSource = 'google_organic';
    }

    if (this.tracingInfo.utmSource == '' && this.tracingInfo.referrer.indexOf('linkedin.com') >= 0) {
      this.tracingInfo.utmSource = 'linkedin';
    }

    if (this.tracingInfo.utmSource == '' && this.tracingInfo.referrer.indexOf('xing.com') >= 0) {
      this.tracingInfo.utmSource = 'xing';
    }
  }

  ngOnDestroy(): void {
    this.sessionEnded$.next();
    this.sessionEnded$.complete();
  }
}
