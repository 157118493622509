import { Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from '@sc-ui';
import { TracingService } from 'src/services/tracing.service';

@Component({
  selector: 'app-job-check-cta-card',
  templateUrl: './job-check-cta-card.component.html',
  styleUrls: ['./job-check-cta-card.component.scss']
})
export class JobCheckCtaCardComponent {

  @Input()
  ctaUrl = '/stellencheck';

  constructor(private ga : GoogleAnalyticsService, public tracingService : TracingService) { }

  goToBooking()
  {    
    this.ga.track('bookingclick');
    window.open(this.tracingService.getDemoLink(), '_blank').focus();
  }

}
