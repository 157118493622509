export let posts = [
    {
        "Uri": "2021-12-16-skillconomy-spendet-10000-euro-an-dkhw",
        "Timestamp": "2021/12/16",
        "Headline": "Wie passt skillconomy in Ihre Talent Acquisition Strategie?",
        "Teaser": "Das Recruiting hat sich innerhalb der HR zu einem der spannendsten und strategisch wichtigsten Arbeitsfelder entwickelt - und stark verändert. Da ist einerseits die Machtumkehr zwischen Kandidat:in und Arbeitgeber, die Spielregeln werden neu definiert. Auf der anderen Seite sind Recruiting ...",
        "Category": "neues2",
        "Author": {
          "Firstname": "Lars",
          "Lastname": "Branscheid",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/lars-avatar.jpg"
        }
    }
]